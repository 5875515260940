import classNames from 'classnames'
import React from 'react'
import ChevronLeft from 'wix-ui-icons-common/ChevronLeft'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import {Title} from '../../../list-title'
import {TodayButton} from '../../today-button'
import {MobileMonthlyDays} from './days'
import s from './mobile-monthly-calendar.scss'
import {MobileMonthlyCalendarProps} from '.'

export const MobileMonthlyCalendar: React.FC<MobileMonthlyCalendarProps> = ({
  subCalendarMonth,
  addCalendarMonth,
  resetCalendar,
  fullMonthText,
}: MobileMonthlyCalendarProps) => (
  <div data-hook={'monthly-mobile-calendar'}>
    <Title />
    <div className={s.controls}>
      <div className={s.monthControls}>
        <button
          onClick={() => subCalendarMonth()}
          type="button"
          className={classNames(s.back, s.monthButton)}
          data-hook={'calendar-previous-month-button'}
        >
          <ChevronLeft size="1.5em" />
        </button>
        <div className={s.month} data-hook={'calendar-month'}>
          {fullMonthText}
        </div>
        <button
          onClick={() => addCalendarMonth()}
          type="button"
          className={classNames(s.next, s.monthButton)}
          data-hook={'calendar-next-month-button'}
        >
          <ChevronRight size="1.5em" />
        </button>
      </div>
      <TodayButton onClick={resetCalendar} dataHook={'calendar-today-button'} />
    </div>
    <div className={s.days}>
      <MobileMonthlyDays />
    </div>
  </div>
)
