import React from 'react'
import ChevronLeft from 'wix-ui-icons-common/ChevronLeft'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import {Title} from '../../list-title'
import {TodayButton} from '../today-button'
import {MonthlyCalendarGrid} from './grid'
import s from './monthly-calendar.scss'
import {MonthlyCalendarProps} from '.'

export const MonthlyCalendar = ({
  addCalendarMonth,
  subCalendarMonth,
  resetCalendar,
  fullMonthText,
  t,
}: MonthlyCalendarProps) => (
  <div className={s.monthlyCalendar} data-hook={'monthly-desktop-calendar'}>
    <Title />
    <div className={s.controls}>
      <div className={s.monthControls}>
        <button
          onClick={() => subCalendarMonth()}
          type="button"
          className={s.monthButton}
          data-hook={'calendar-previous-month-button'}
          aria-label={t('a11y.previousMonth')}
        >
          <ChevronLeft size="1.5em" />
        </button>
        <div className={s.month} data-hook={'calendar-month'}>
          {fullMonthText}
        </div>
        <button
          onClick={() => addCalendarMonth()}
          type="button"
          className={s.monthButton}
          data-hook={'calendar-next-month-button'}
          aria-label={t('a11y.nextMonth')}
        >
          <ChevronRight size="1.5em" />
        </button>
      </div>
      <div className={s.today}>
        <TodayButton onClick={resetCalendar} dataHook={'calendar-today-button'} />
      </div>
    </div>
    <div className={s.grid}>
      <MonthlyCalendarGrid />
    </div>
  </div>
)
